<script lang="ts">import { onMount } from "svelte";
import Output from "./Output.svelte";
import "axios";
import { fetchComponent } from "./utils";
let components = [];
let current = 0;
let error = false;
const parse = (params) => {
    const resultArray = [];
    // Iterate over each parameter in the URLSearchParams
    params.forEach((value, key) => {
        // Extract the index, property name, and object key from the parameter key using regular expressions
        const matches = key.match(/variables\[(\d+)\]\[(\w+)\](?:\[(\w+)\])?/);
        if (matches) {
            const index = parseInt(matches[1]);
            const property = matches[2];
            const subProperty = matches[3];
            // Create or update the variable object in the result array
            resultArray[index] = resultArray[index] || {};
            // Assign the value to the appropriate property or subproperty dynamically
            if (subProperty) {
                // Create or update the nested subproperty object
                resultArray[index][property] = resultArray[index][property] || {};
                resultArray[index][property][subProperty] = value;
            }
            else {
                resultArray[index][property] = value;
            }
        }
    });
    return resultArray;
};
export const replaceVariables = (str, variables) => {
    return variables.reduce((c, v) => {
        return c.replaceAll(`[${v.identifier || v.property.identifier}]`, typeof v.value === 'undefined' || v.value === '' ? v.defaultValue : v.value);
    }, str);
};
onMount(async () => {
    const queryParams = {};
    const params = new URLSearchParams(window.location.search);
    for (let [key, value] of params.entries()) {
        queryParams[key] = value;
    }
    const version = await fetchComponent(queryParams.slug);
    if (!version)
        return error = true;
    let variables = parse(params);
    if (!variables.length)
        variables = version.source.variables;
    components = version.source.files.map((file, i) => ({
        id: i,
        name: file.filename || file.fileType.filename,
        type: "svelte",
        source: replaceVariables(file.content, variables),
    })).sort((a, b) => a.name === 'App' ? -1 : 1);
    current = components[0].id;
});
const worker = new Worker("/worker.js");
let compiled;
worker.addEventListener("message", (event) => {
    compiled = event.data;
});
function compile(_components) {
    worker.postMessage(_components);
}
$: if (components.length)
    compile(components);
</script>

<main>
	{#if error}
		<div class="error-container">
			<h1>Error Occurred</h1>
		</div>
	{:else}
		<Output {compiled} />
	{/if}
</main>

<style >
	.error-container {
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		width:100%;
		text-align:center;
	}
	h1 {
		font-size:3.2rem;
	}
	:global(body) {
		margin:0;
		overflow:hidden;
	}
</style>